import React, {  useEffect, useState } from 'react';
import { getQuestion } from './question-chat';



const ActionProvider = ({ createChatBotMessage, setState, children }) => {

  const [form, setForm] = useState([])

  const getForm = async () => {
    try {
      const data = await getQuestion();
      setForm(data)
    } catch (err) {
      console.error(err);
    }
  };
  
  useEffect(() => {
    getForm()
   }, []);

  const handleQ = (id) => {
    console.log(form)
    const message = createChatBotMessage(
      form[id].question,
      {
        payload: { uid: id },
        loading: true,
        terminateLoading: true,
        withAvatar: true
      }
    );
    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, message],
    }));
  };

  const addExplanation = (id) => {
    const message = createChatBotMessage(
     "Pourriez vous m'en dire plus ? Mon intelligence dépend de ce que vous pouvez me dire",
      {
        payload: { uid: id },
        loading: true,
        terminateLoading: true,
        withAvatar: true
      }
    );
    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, message],
    }));
  };

  const validate = (id) => {
    const message = createChatBotMessage(
      `Nous allons vous recommander des produis qui répondront à vos problématiques`,
      {
        loading: true,
        terminateLoading: true,
        withAvatar: true
      }
    );
    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, message],
    }));
  };
  const unkownResponse = (id) => {
    const message = createChatBotMessage(
      `Je n'ai pas bien compris votre réponse pouvez-vous répéter`,
      {
        loading: true,
        terminateLoading: true,
        withAvatar: true
      }
    );
    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, message],
    }));
  };
  return (
    <div>
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, {
          actions: { handleQ , validate, unkownResponse, addExplanation},
        });
      })}
    </div>
  );
};

export default ActionProvider;