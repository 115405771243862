import axios from 'axios'

export const getQuestion = async() => {

    try {
    
    const result = await axios.get('https://cactus.gydtech.io/api/questions')
    return result.data
        
    } catch (error) {
        console.log(error)
    }
}




// const questions = [
//     {
//         uid:0,
//         question: 'Puis-je vous demander votre age ?'
//     },
//     {
//         uid:1,
//         question: 'Quand un chat vous demande si vous êtes un homme ou une femme , vous répondez quoi ?'
//     },
//     {
//         uid:2,
//         question: 'Pouvez-vous nous indiquer les 3 problématiques principales pour lesquelles vous souhaiteriez obtenir un effet ?'
//     },
//     {
//         uid:3,
//         question: 'Voulez-vous ajouter autre chose?'
//     },
//     {
//         uid:4,
//         question: 'Selon votre ressenti , quel est votre type de peau (sèche, grasse, mixte, sensible, mature, etc...) ?'
//     },
//     {
//         uid:5,
//         question: 'Sur quelles zones de votre visage souhaitez vous travailler ?'
//     },
//     {
//         uid:6,
//         question: 'Quelle texture souhaitez-vous pour votre produit cosmétique ?'
//     },
//     {
//         uid:7,
//         question: 'Quelle routine de soin idéale voudriez vous utiliser vous au quotidien sur votre peau ?'
//     }
// ]