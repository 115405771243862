import './App.css';
import Chatbot from 'react-chatbot-kit'
import 'react-chatbot-kit/build/main.css'
import config from './bot/config.js';
import MessageParser from './bot/MessageParser.js';
import ActionProvider from './bot/ActionProvider.js';
import { getQuestion } from './bot/question-chat';
import { createChatBotMessage } from 'react-chatbot-kit';
import React, {  useEffect, useState } from 'react';


function App() {

  const [customConfig, setCustomconfig] = useState(config)
  const [flag, setFlag] = useState(false)
  console.log(customConfig)
  const getForm = async () => {
    try {
      const data = await getQuestion();
      
      if(customConfig.initialMessages.length == 1){
      customConfig.initialMessages.push(createChatBotMessage(
       data[0].question,
        {
          withAvatar: false,
          delay: 500, 
        }
      ))
      setCustomconfig(customConfig)
      setFlag(true)
    }
      // setCustomconfig(config.initialMessages.createChatBotMessage(
      //   'Quelle age avez vous ?',
      //   {
      //     withAvatar: false,
      //     delay: 500, 
      //   }
      // ))
    } catch (err) {
      console.error(err);
    }
  };
  
  useEffect(() => {
    getForm()
   }, [customConfig]);


  const saveMessages = (messages, HTMLString) => {
    localStorage.setItem('chat_messages', JSON.stringify(messages));
  };

  const loadMessages = () => {
    const messages = JSON.parse(localStorage.getItem('chat_messages'));
    return messages;
  };

  const deleteLocalStorage = () => {
    window.localStorage.removeItem('chat_messages');
  }

  useEffect(() => {
    deleteLocalStorage()
  }, []);

  return (
    <div className="App">
       {flag && <Chatbot
        config={customConfig}
        messageParser={MessageParser}
        actionProvider={ActionProvider}
        saveMessages={saveMessages}
        placeholderText='Ecrivez votre réponse'
      />}
    </div>
  );
}

export default App;
