import { createChatBotMessage } from 'react-chatbot-kit';
import { getQuestion } from './question-chat';

const begin = 'Bonjour bienvenue chez Cactus! Nous allons choisir ensemble la cosmétique la plus adaptée à vos besoins.'
const botName = 'Cactus'
const config = {
  initialMessages: [
    createChatBotMessage(begin), 
    ],
  botName: botName,
  state: {
    ageCustomer: null,
  },
  customComponents: {
    // Replaces the default header
   header: () => <div style={{ backgroundColor: 'grey', padding: "5px", borderRadius: "3px" }}>{botName}</div>
 }
};


export default config;