import React, {  useEffect, useState } from 'react';
import axios from 'axios';
import { getQuestion } from './question-chat';

const MessageParser = ({ children, actions }) => {
  const [uid, setUid] = useState(0)
  const [explanation, setExplanation] = useState(false)
  const [form, setForm] = useState([])
  const [start, setStart] = useState(true)

  const getForm = async () => {
    try {
      const data = await getQuestion();
      setForm(data)
    } catch (err) {
      console.error(err);
    }
  };
  
  useEffect(() => {
    getForm()
   }, []);
  
  const submit = async (form) => {

    console.log(form)

    // form[2].response += ' ' + form[3].response
    // delete form[3]
    // form.forEach((element, index) => {
    //   if (!Number.isInteger(element.uid)){
    //     form[index -1].response += ' ' + form[index].response
    //   }
    // });

    // form = form.filter(el => Number.isInteger(el.uid))

    const domain = 'https://democactus.gydtech.io/';
     try {
      const request = {
        session: form,
        version: 2
      }
      const response = await axios.post('https://cactus.gydtech.io/api/forms/cosmetic', request);
      console.log(response)
      if(response.data.id)
        window.location = domain + `?idQuestionner=${response.data.id}`
      
    } catch (error) {
      console.log(error);
    } 
  };



  const saveMessages = (uid, response, question, brief) => {
    let history = []
    let toSave = { uid, question: question, response: response , brief}
    if (JSON.parse(localStorage.getItem('chat_messages'))){
      history = JSON.parse(localStorage.getItem('chat_messages'));
      history.push(toSave)
    }
    else
      history.push(toSave)
    localStorage.setItem('chat_messages', JSON.stringify(history));
  };

  const getHistory = (response, question) => {
      return JSON.parse(localStorage.getItem('chat_messages'));
  };

  const parse = (message) => {
    // if(message.length < 15 && uid > 4 && explanation === false){
    //   saveMessages(uid-1, message, form[uid-1].question)
    //   setExplanation(true)
    //   return actions.addExplanation(uid)
    // }
    // else if (message.length > 0 && uid === 8) {
    //   if(explanation)
    //   saveMessages(uid-0.9, message,'')
    //   else
    //   saveMessages(uid-0.9, message,' questions[uid-1].question')
    //   submit(JSON.parse(localStorage.getItem('chat_messages')))
    //   return actions.validate();
    // }
    // else if (message.length > 0 ) {
    //   if(explanation){
    //     saveMessages(uid -0.9, message,'')
    //     setExplanation(false)
    //   } 
    //   else{
    //     saveMessages(uid-1, message, form[uid -1].question)
    //   }
    //   setUid(prev => prev + 1) 
    //   return actions.handleQ(uid);
    // }
    // else {
    //   return actions.unkownResponse();
    // }
    // console.log(uid)
    // if(start){
    //   setStart(false)
    //   return actions.handleQ(0)
    // }
    if(form.length -1 == uid){
      saveMessages(uid, message, form[uid].question, form[uid].brief)
      submit(JSON.parse(localStorage.getItem('chat_messages')))
      return actions.validate(uid)
    }
    // else if(form[uid].min_len > message.length ) {
    //   return actions.addExplanation(uid)
    // }
    else{
      saveMessages(uid, message, form[uid].question, form[uid].brief)
      setUid(prev => prev + 1) 
      return actions.handleQ(uid + 1);
    }
    
  };

  return (
    <div>
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, {
          parse: parse,
          actions,
        });
      })}
    </div>
  );
};

export default MessageParser;